<template>
	<div class="finance">
		<search-header :currentActive="5"></search-header>
		<div class="container p-0 mb-4">
			<h4 class="mt-4">理财产品</h4>
			<div class="row m-0">
				<div class="float-left p-0 mt-3" :class="{'ml-15' : i % 3 !== 0 && i > 0}" v-for="m,i in investList" :key="i">
					<div class="box">
						<div class="float-left">
							<h6 class="width-240" style="height:40px">{{m.name}}</h6>
							<div class="mt-2 clearfix">
								<div class="p-1 bg-warning text-center text-white rounded-pill f-12 float-left mr-2"
									style="min-width: 80px;">
									{{m.bankId}}
								</div>
								<div class="p-1 bg-warning text-center text-white rounded-pill f-12 float-left"
									style="min-width: 80px;">
									{{getInvestValue(m.type)}}
								</div>
							</div>
							<div class="f-14 mt-2 mb-1" style="width: 250px;">
								<span class="text-gray-777">年化收益率:</span>
								<span class="text-red">{{m.earningRate}}%</span>
								<span class="text-gray-777"> , </span>
								<span class="text-success">{{m.startingQuantity}}元</span>
								<span class="text-gray-777">起购</span>
							</div>
						</div>
						<div class="float-left">
							<div class="d-flex justify-content-center align-items-center border-left"
								style="height: 110px;width: 98px;">
								<a :href="m.url" target="_blank">
									<div>查看</div>
									<div>详情</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h4 class="mt-4">贷款产品</h4>
			<div class="row m-0">
				<div class="float-left p-0 mt-3" :class="{'ml-15' : j % 3 !== 0 && j > 0}" v-for="m,j in loanList" :key="j">
					<div class="box">
						<div class="float-left">
							<h6 class="width-240" style="height:40px">{{m.name}}</h6>
							<div class="mt-2 clearfix">
								<div class="p-1 bg-warning text-center text-white rounded-pill f-12 float-left mr-2"
									style="min-width: 80px;">
									{{m.bankId}}
								</div>
								<div class="p-1 bg-warning text-center text-white rounded-pill f-12 float-left"
									style="min-width: 80px;">
									{{ getLoanValue(m.type) }}
								</div>
							</div>
							<div class="f-14 mt-2 mb-1" style="width: 250px;">
								<span class="text-gray-777">年利率:</span>
								<span class="text-success">{{m.interestRate}}%</span>
								<span class="text-gray-777"> , </span>
								<span class="text-gray-777">最高额度</span>
								<span class="text-red">{{m.maxMoney}}元</span>
							</div>
						</div>
						<div class="float-left">
							<div class="d-flex justify-content-center align-items-center border-left"
								style="height: 110px;width: 98px;">
								<a :href="m.url" target="_blank">
									<div>查看</div>
									<div>详情</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h4 class="mt-4">合作伙伴</h4>
			<div class="row m-0">
				<div class="float-left p-0 mt-3" :class="{'ml-15' : k % 5 !== 0 && k > 0}" v-for="m,k in partnerList" :key="k">
					<div class="partner-box">
						<a :href="m.url" target="_blank">
							<img :src="m.image" class="image">
						</a>
					</div>
				</div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loanList: [],
				investList:[],
				partnerList:[],
				investType:[],
				loanType:[],
			}
		},
		computed: {
			getLoanValue() {
				return function (type) {
					let value = '';
					let t = this.loanType.find(x=>x.detailValue = type);
					if(t) {
						return t.text
					}
					return value
				}
			},
			getInvestValue() {
				return function (type) {
					let value = '';
					let t = this.investType.find(x=>x.detailValue = type);
					if(t) {
						return t.text
					}
					return value
				}
			}
		},
		methods: {
			getList() {
				this.$http.post(this.$api.finance.investList, {param:{},size:15,current:1}).then(res => {
					this.investList = res.result.records;
				})
				this.$http.post(this.$api.finance.loanList, {param:{},size:15,current:1}).then(res => {
					this.loanList = res.result.records;
				})
				this.$http.post(this.$api.finance.partnerList, {param:{},size:15,current:1}).then(res => {
					this.partnerList = res.result.records;
				})
				this.$http.post(this.$api.dict.findDickTypeName, {
					typeName: 'FINANCE_INVEST_TYPE'
				}).then(res => {
					this.investType = res.result[0].dictionaryDetails;
				})
				this.$http.post(this.$api.dict.findDickTypeName, {
					typeName: 'FINANCE_LOAN_TYPE'
				}).then(res => {
					this.loanType = res.result[0].dictionaryDetails;
				})  
			}
		},
		created() {
			this.getList();
		}
	}
</script>

<style scoped>
	.finance .box {
		border: #cccccc solid 1px;
		padding: 10px 0px 10px 15px;
		border-radius: 10px;
		width: 370px;
		height: 130px;
	}

	.finance .box .text-red {
		color: #ff6329;
	}

	.finance .ml-15 {
		margin-left: 15px;
	}
	
	.partner-box {
		border: #cccccc solid 1px;
		border-radius: 10px;
		overflow: hidden;
		width: 216px;
		height: 70px;
	}

	.image {
		width: 216px;height: 70px;
	}
</style>
